<template>
  <v-app>
    <Content v-if="$store.state.isLogged || $route.meta.isAlway"/>
    <GoTop />
  </v-app>
</template>

<script>
import Content from "@/components/Content"
import GoTop from "@/components/GoTop";

export default {
  name: "App",
  components: {
    Content,
    GoTop
  },
};
</script>