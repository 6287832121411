import "material-design-icons-iconfont/dist/material-design-icons.css"
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// export default new Vuetify({});
export default new Vuetify({
    buttons: {
        capitalize: false,
    },
    icons: {
        iconfont: 'mdi',
    }
});
