import Vue from "vue";
import VueRouter from "vue-router";
// import { checkLogin, checkLoginWithRedirect } from "@/services/api";
import { checkLogin } from "@/services/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { keepAlive: false, isAlway: true }
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("@/views/Register.vue"),
  //   meta: { keepAlive: false }
  // },
  // {
  //   path: "/forgotpassword",
  //   name: "ForgotPassword",
  //   component: () => import("@/views/ForgotPassword.vue"),
  //   meta: { keepAlive: false }
  // },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/accept",
    name: "Accept",
    component: () => import("@/views/Accept.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/processing",
    name: "Processing",
    component: () => import("@/views/Processing.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/ready",
    name: "Ready",
    component: () => import("@/views/Ready.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/complete",
    name: "Complete",
    component: () => import("@/views/Complete.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: () => import("@/views/Cancel.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  },
  {
    path: "*",
    component: () => import("@/views/Home.vue"),
    beforeEnter(to, from, next) { checkLogin(to, from, next) },
    meta: { keepAlive: false }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next)=>{
//   if ( to.name !== 'login' && !this.isloggedin ){
//     next({
//       path: 'login',
//       replace: true
//     })
//   } else {
//     next();
//   }
// })

export default router;
