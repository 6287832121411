import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    deliveryCount: {
      badge_0: null,
      badge_1: null,
      badge_2: null
    },
    startDate: null,
    endDate: null,
    last_key: ""
  },

  getters: {
    isLogin(state) {
      return state.isLogged;
    },
    deliveryCount(state) {
      return state.deliveryCount;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    last_key(state) {
      return state.last_key;
    },
  },

  mutations: {
    SET_LOGGED_IN(state){
      state.isLogged = true;
    },
    SET_LOGGED_OUT(state) {
      state.isLogged = false;
    },
    SET_DELIVERY_COUNT(state, value) {
      state.deliveryCount = value;
    },
    SET_START_DATE(state, value) {
      state.startDate = value;
    },
    SET_END_DATE(state, value) {
      state.endDate = value;
    },
    SET_LAST_KEY(state, value) {
      state.last_key = value;
    },
  },

  actions: {
    async doLogin({ commit, dispatch }, { username, password }) {
      if (await api.login(username, password)) {
        commit("SET_LOGGED_IN");
      } else {
        dispatch("doLogout");
      }
    },
    async doLogout({ commit }) {
      commit("SET_LOGGED_OUT");
      await api.logoff();
    },
    async restoreLogin({ commit }) {
      if (await api.isLoggedIn()) {
        commit("SET_LOGGED_IN");
      }
    },
    async setDeliveryStatusCount({ commit }) {
      let results = await api.getVendorDeliveryOnProcess();
      let deliveryCount = { badge_0: null, badge_1: null, badge_2: null };
      if (results!=null && results.length>0) {
        results.forEach(item => {
          if (item?.deliveryStatus == 0)
            deliveryCount.badge_0 = item.count;
          else if (item?.deliveryStatus == 1)
            deliveryCount.badge_1 = item.count;
          else if (item?.deliveryStatus == 2)
            deliveryCount.badge_2 = item.count;
        });
      };
      commit("SET_DELIVERY_COUNT", deliveryCount);
    },
    async setStartDate({ commit }, value) {
      commit("SET_START_DATE", value);
    },
    async setEndDate({ commit }, value) {
      commit("SET_END_DATE", value);
    },
    async setLastKey({ commit }, value) {
      commit("SET_LAST_KEY", value);
    },
  },
  
  modules: {},
});