export const apiUrl = "https://vgyr4kanz1.execute-api.ap-southeast-1.amazonaws.com/Prod";

export const imageUrl = "https://villa-remove-bg-small-output.s3-ap-southeast-1.amazonaws.com";

export const server = {
    USERNAME: `username`,
    ACCESS_TOKEN: `accessToken`,
    ID_TOKEN: `idToken`,
    REFRESH_TOKEN: `refreshToken`,
    EXPIRES_IN: `expiresIn`,
    EXPIRES_TIME: `expiresTime`,
    USER_INFO: "userInfo"
};